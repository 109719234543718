import { toInteger } from 'lodash-es'
import fetchGql from '@/utils/fetchGql'
import type IBanner from '@/types/Storyblok/General/Banner'
import listSinglepressreleaseItems from '@/gql/listSinglepressreleaseItems.gql'

const parseData = (data: any) => {
  const bannerData: IBanner = ref([])

  data.forEach((banner: any) => {
    const bannerDate = getDate(banner.content.publishDate)

    bannerData.value.push({
      title: banner?.content?.title,
      text: banner?.content?.lead,
      day: bannerDate?.day,
      month: bannerDate?.month,
      year: bannerDate?.year,
      slug: banner.full_slug.replace('press-releases/', '')
    })
  })
  return bannerData.value
}

const getDate = (date: string) => {
  if (!date) return {}
  const publishDate = new Date(date)

  const day = String(publishDate.getDate()).padStart(2, '0')
  const month = publishDate.getMonth() + 1
  const year = publishDate.getFullYear()

  return { day, month, year }
}

export const useFetchPressReleases = () => {
  const fetchPressReleases = (language: string, perPage: number, page: number, year: number) => {
    const variables = {
      language: language === 'en' ? '' : `${language}/*`,
      perPage: toInteger(perPage),
      page: toInteger(page),
      slug: `press-releases/${year}/*`
    }

    return fetchGql(listSinglepressreleaseItems, variables).then((response: any) =>
      parseData(response.SinglepressreleaseItems.items)
    )
  }

  return { fetchPressReleases }
}
